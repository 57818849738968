.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  background-color: black;
  min-height: 1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Button-wod {
  background-color: #c5c5c5;
  color: rgb(3, 3, 3);
  font-size: 20px;
  padding: 10px 60px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
}

.Button-group {
  display: flex;
}

.App-link {
  color: #61dafb;
}

.Header-logo {
  background-color: #17559e;
  height: 10vmin;
  width: 10px;
  pointer-events: none;
}

.ReactModal__Overlay {
  -webkit-perspective: 600;
  perspective: 600;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
}

.modal-content {
  -webkit-transform: scale(1) rotateX(-30deg);
  transform: scale(1) rotateX(-30deg);
  background-color: white;
}

.modal-content--after-open {
  -webkit-transform: scale(1) rotateX(0deg);
  transform: scale(1) rotateX(0deg);
  transition: all 150ms ease-in;
}

.modal-overlay--before-close {
  opacity: 0;
}

.modal-content--before-close {
  -webkit-transform: scale(0.5) rotateX(30deg);
  transform: scale(0.5) rotateX(30deg);
  transition: all 150ms ease-in;
}

.modal-content.modal-dialog {
  border: none;
  background-color: transparent;
}

.modal-body{
  align-items: center;
  justify-content: center;
  text-align: center;
}

.modal-footer{
  align-items: center;
  justify-content: center;
  text-align: center;
}

.modal-btn-secondary{
  background-color: #c5c5c5;
  color: rgb(3, 3, 3);
  font-size: 16px;
  padding: 5px 30px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
}

.modal-btn-primary{
  background-color: #c5c5c5;
  color: rgb(3, 3, 3);
  font-size: 16px;
  padding: 5px 30px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
